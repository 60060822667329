import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './containers/App';
import history from './history';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
	<Router history={history}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Router>,
	document.getElementById('root')
);
