import { useRef, useEffect, useState } from 'react';
import { BASE } from './api';
const RECONNECT_INTERVAL = 500;

class Socket {
	constructor(onMessageRef, queueRef, setConnected) {
		this.onMessageRef = onMessageRef;
		this.queueRef = queueRef;
		this.setConnected = setConnected;
		this.shouldBeConnected = true;
		this.onOpen = this.onOpen.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onError = this.onError.bind(this);
		this.onMessage = this.onMessage.bind(this);
		this.connect = this.connect.bind(this);
		this.send = this.send.bind(this);
		this.disconnect = this.disconnect.bind(this);
	}

	onOpen() {
		this.setConnected(true);
		while(this.queueRef.current.length) {
			const message = this.queueRef.current.shift();
			this.send(message);
		}
	}

	onClose() {
		if(this.shouldBeConnected) {
			clearTimeout(this.reconnect);
			setTimeout(this.connect, RECONNECT_INTERVAL);
		}
		this.setConnected(false);
	}

	onError(...args) {
		console.warn('WS error:', args);
	}

	onMessage(e) {
		try {
			const message = JSON.parse(e.data);
			this.onMessageRef.current(message);
		} catch(err) {
			console.warn('Received non-json message', e);
		}
	}

	connect() {
		if(!this.shouldBeConnected) {
			return;
		}
		const { host, protocol } = window.location;
		const socket = new WebSocket(`${protocol.replace('http', 'ws')}//${`${host}${BASE}`}/ws`);
		socket.addEventListener('open', this.onOpen);
		socket.addEventListener('error', this.onError);
		socket.addEventListener('close', this.onClose);
		socket.addEventListener('message', this.onMessage);
		this.socket = socket;
	}

	send(message) {
		const { socket } = this;
		if(socket && socket.readyState === WebSocket.OPEN) {
			const listener = () => this.queueRef.current.push(message);
			socket.addEventListener('error', listener);
			try {
				socket.send(message);
			} catch(e) {
				console.warn('Failed to send message', e);
			}
			socket.removeEventListener('error', listener);
		} else {
			this.queueRef.current.push(message);
		}
	}

	disconnect() {
		this.shouldBeConnected = false;
		if(this.socket) {
			this.socket.close();
		}
		clearTimeout(this.reconnect);
	}
}

export function useWebSocket(onMessage) {
	const [connected, setConnected] = useState(false);
	const queueRef = useRef([]);
	const sendRef = useRef();
	const onMessageRef = useRef(onMessage);
	onMessageRef.current = onMessage;
	useEffect(() => {
		const socket = new Socket(onMessageRef, queueRef, setConnected);
		sendRef.current = socket.send;
		socket.connect();
		return socket.disconnect;
	}, []);
	return [message => {
		const serialized = JSON.stringify(message);
		if(!sendRef.current) {
			queueRef.current.push(serialized);
		} else {
			sendRef.current(serialized);
		}
	}, connected];
}
