import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useWebSocket } from '../../websocket';
import OrderList from '../../components/orders/OrderList';
import Order from '../../components/orders/Order';

function orderComp(lhs, rhs) {
	if(lhs.status !== rhs.status) {
		if(lhs.status === 'NONE') {
			return -1;
		}
		return 1;
	}
	const d = lhs.delivery - rhs.delivery;
	if(d) {
		return d;
	} else if(lhs.reference < rhs.reference) {
		return -1;
	} else if(lhs.reference > rhs.reference) {
		return 1;
	}
	return 0;
}

function updateOrder(id, update) {
	return array => {
		return array.map(o => {
			if(o._id === id) {
				const order = Object.assign({}, o);
				update(order);
				return order;
			}
			return o;
		});
	};
}

function setDate(order) {
	if(order.delivery) {
		order.delivery = new Date(order.delivery);
	}
	return order;
}

function parseMessage(setOrders) {
	function setSorted(orders) {
		if(typeof orders === 'function') {
			return setOrders(o => orders(o).sort(orderComp));
		}
		orders.sort(orderComp);
		return setOrders(orders);
	}
	return message => {
		if(message.type === 'Orders') {
			setSorted(message.payload.map(setDate));
		} else if(message.type === 'Pick') {
			setOrders(updateOrder(message.payload.order, order => {
				order.lines = order.lines.map(l => {
					if(l.item === message.payload.item) {
						const line = Object.assign({}, l);
						line.picked = message.payload.quantity;
						return line;
					}
					return l;
				});
			}));
		} else if(message.type === 'UpdateOrder') {
			setSorted(updateOrder(message.payload.order, order => {
				Object.assign(order, message.payload.updates);
				setDate(order);
			}));
		} else if(message.type === 'AddOrders') {
			const ids = new Set(message.payload.map(o => o._id));
			setSorted(array => {
				return array.filter(o => !ids.has(o._id)).concat(message.payload.map(setDate));
			});
		} else if(message.type === 'RemoveOrder') {
			setOrders(array => array.filter(o => o._id !== message.payload));
		}
	};
}

export default function Orders() {
	const [orders, setOrders] = useState(null);
	const [send, connected] = useWebSocket(parseMessage(setOrders));
	function pick(order, item, quantity) {
		send({
			type: 'Pick',
			payload: {
				order, item, quantity
			}
		});
	}
	function register(order) {
		send({
			type: 'Register',
			payload: {
				order
			}
		});
	}
	function print(order) {
		send({
			type: 'Print',
			payload: {
				order
			}
		});
	}
	function remove(order) {
		send({
			type: 'RemoveOrder',
			payload: order
		});
	}
	return <Switch>
		{orders && <Route path="/orders/:id" children={({ match }) => <Order
			order={orders.find(o => o._id === match.params.id)}
			pick={pick}
			register={() => register(match.params.id)}
			remove={() => remove(match.params.id)}
			print={() => print(match.params.id)} />}
		/>}
		<Route>
			<OrderList connected={connected} orders={orders} />
		</Route>
	</Switch>;
}
