import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

function Header({ order, picked, allPicked, register, print }) {
	const [printed, setPrinted] = useState(false);
	if(order.status === 'DONE') {
		return <>
			Aangemeld <Button disabled={printed} color="primary" onClick={e => {
				e.preventDefault();
				print();
				setPrinted(true);
			}}>
				Opnieuw printen
			</Button>
		</>;
	} else if(picked) {
		return <>
			{!allPicked && <>
				Let op: Nog niet alle items zijn gepakt.
				<br />
			</>}
			<Button color="primary" onClick={e => {
				e.preventDefault();
				if(window.confirm('Weet je zeker dat je deze order wil aanmelden?')) {
					register();
				}
			}}>
				Aanmelden
			</Button>
		</>;
	}
	return null;
}

export default function Order({ order, pick, register, print, remove }) {
	if(!order) {
		return <Redirect to="/orders" />;
	}
	let picked = false;
	let allPicked = true;
	const lines = order.lines.map(line => {
		if(line.picked > 0) {
			picked = true;
		} else if(line.picked !== line.quantity) {
			allPicked = false;
		}
		return <Row key={line.item}>
			<Col>{line.item}</Col>
			<Col>{line.name}</Col>
			<Col>{line.quantity} items / {line.colli} colli</Col>
			<Col>
				<Button size="sm" color="danger" onClick={e => {
					e.preventDefault();
					pick(order._id, line.item, 0);
				}}>-</Button>
				{' '}{line.picked}{' '}
				<Button size="sm" color="success" onClick={e => {
					e.preventDefault();
					pick(order._id, line.item, line.quantity);
				}}>+</Button>
			</Col>
		</Row>;
	});
	return <Container>
		<h1>{order.reference}</h1>
		<p>
			{order.name}<br />
			{order.address}<br />
			{order.postalCode} {order.country}
		</p>
		<Row className="mb-4">
			<Col xs={6}>
				<Header order={order} picked={picked} allPicked={allPicked} register={register} print={print} />
			</Col>
			<Col className="text-right" xs={6}>
				<Button color="danger" onClick={e => {
					e.preventDefault();
					if(window.confirm('Weet je zeker dat je deze order wil verwijderen?')) {
						remove();
					}
				}}>Verwijderen</Button>
			</Col>
		</Row>
		{lines}
	</Container>;
}
