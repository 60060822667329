import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function Home({ user }) {
	const picker = user.roles.includes('PICKER');
	const viewOrders = user.roles.includes('VIEW_ORDERS');
	return <Container>
		<h1>Welcome, {user.email}</h1>
		{picker && <p>
			<Link to="/orders">Orders</Link>
		</p>}
		{viewOrders && <p>
			<Link to="/export">Export</Link>
		</p>}
	</Container>;
}
