import axios from 'axios';
import HttpStatus from 'http-status-codes';

export const BASE = '/api';

const client = axios.create({
	baseURL: BASE
});

function getLoginUrl() {
	return `${window.location.origin}${BASE}/login?redirect=${encodeURIComponent(window.location)}`;
}

export async function getMe() {
	try {
		const res = await client.get('/auth/me');
		return res.data;
	} catch(err) {
		if(err.response && err.response.data && err.response.status === HttpStatus.UNAUTHORIZED) {
			const { login } = err.response.data;
			window.location = `${login}?login=${encodeURIComponent(getLoginUrl())}`;
			return null;
		}
		throw err;
	}
}
