import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../components/Home';
import Orders from './orders/Orders';
import Export from './export/Export';

export default function Routes({ user }) {
	const picker = user.roles.includes('PICKER');
	const viewOrders = user.roles.includes('VIEW_ORDERS');
	return <Switch>
		{picker && <Route path="/orders">
			<Orders />
		</Route>}
		{viewOrders && <Route path="/export">
			<Export />
		</Route>}
		<Route>
			<Home user={user} />
		</Route>
	</Switch>;
}
