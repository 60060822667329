import React, { useState } from 'react';
import DownloadOrders from '../../components/export/DownloadOrders';
import { BASE } from '../../api';

export default function Export() {
	const [from, setFrom] = useState(() => {
		const d = new Date();
		return new Date(d.getFullYear(), d.getMonth(), 1);
	});
	const [till, setTill] = useState(new Date());
	function download() {
		const url = `${window.location.origin}${BASE}/export/orders/${encodeURIComponent(from.toISOString())}/${encodeURIComponent(till.toISOString())}`;
		window.location = url;
	}
	return <DownloadOrders from={from} setFrom={setFrom} till={till} setTill={setTill} download={download} />;
}
