import React from 'react';
import DatePicker from 'react-datepicker';
import { Container, FormGroup, Col, Button } from 'reactstrap';

export default function DownloadOrders({ from, setFrom, till, setTill, download }) {
	return <Container>
		<h1>Export registered orders</h1>
		<FormGroup row>
			<Col sm={6}>
				<label className="d-block font-weight-bold small">Start date</label>
				<DatePicker selected={from} onChange={setFrom} className="form-control" startDate={from} endDate={till} maxDate={till} selectsStart />
			</Col>
			<Col sm={6}>
				<label className="d-block font-weight-bold small">End date</label>
				<DatePicker selected={till} onChange={setTill} className="form-control" startDate={from} endDate={till} minDate={from} selectsEnd />
			</Col>
		</FormGroup>
		<FormGroup>
			<Button color="primary" onClick={e => {
				e.preventDefault();
				download();
			}}>Export</Button>
		</FormGroup>
	</Container>;
}
