import React from 'react';
import { Container, ListGroup, ListGroupItem, Progress, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const PERCENT = 100;

export default function OrderList({ connected, orders }) {
	return <Container>
		<p>Connected: {`${connected}`}</p>
		{orders ? <ListGroup>
			{orders.map(order => {
				let picked = 0;
				let items = 0;
				order.lines.forEach(l => {
					picked += l.picked;
					items += l.quantity;
				});
				return <ListGroupItem key={order._id}>
					<Link to={`/orders/${order._id}`} className="d-block">
						<Row>
							<Col><h2>{order.reference}</h2></Col>
							{order.status === 'DONE' && <Col className="text-right">Aangemeld</Col>}
						</Row>
						<Progress value={picked * PERCENT / items} />
					</Link>
				</ListGroupItem>;
			})}
		</ListGroup> : <p>Loading...</p>}
	</Container>;
}
